import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { Neighbors } from './TableNeighbors';
import { WebMap } from './SpacingMap';
import Header from '../components/Header/header'

function AppRouter() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" Component={WebMap}/>
        {/* <Route path="/Neighbors" Component={Neighbors} /> */}
        {/* <Route path="/About" Component={AboutProject} /> */}
        {/* <Route path="plot-comparison" Component={PlotNeighbors}/> */}
        {/* Add other routes as needed */}
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default AppRouter;
