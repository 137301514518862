import React, { useEffect, useState } from 'react';
import 'ol/ol.css';
import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import { Vector as VectorSource } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import InteractiveMap from './InteractiveMap';
import { defaultStyle, selectedStyle } from './utilities ';
import './styles.css';
import SelectionTool from '../SelectionTool/SelectionTool';
import { Style, Circle as CircleStyle, Fill } from 'ol/style';
import { fetchStreetsInSantaBarbara, selectByArea, selectByHydrant, selectByStreet } from '../SelectionTool/selectionUtils';
import { FeatureCollection } from 'geojson';
import { Map as OlMap } from 'ol';
import { FaTools, FaChevronDown, FaChevronUp  } from 'react-icons/fa';

type LayerName = 'mainLayer' | 'pointsLayer';

function WebMercatorMap() {
  const [userInput, setUserInput] = useState('');
  const [exceedingFeatures, setExceedingFeatures] = useState<Feature<Geometry>[]>([]);
  const [selectedFeature, setSelectedFeature] = useState<Feature<Geometry> | null>(null);
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [layerVisibility, setLayerVisibility] = useState({
    mainLayer: true,
    pointsLayer: true,
  });
  const [streetData, setStreetData] = useState<FeatureCollection | null>(null); // State for street data
  const [streetName, setStreetName] = useState<string>('');
  const [isStatisticsToolActive, setIsStatisticsToolActive] = useState(false);
  const [activeTool, setActiveTool] = useState<'rectangle' | 'polygon' | 'byHydrant' | 'byStreet' | 'sidebar' | null>(null);
  const [interactiveMode, setInteractiveMode] = useState(false);
  const [lastActiveTool, setLastActiveTool] = useState<'rectangle' | 'polygon' | 'byHydrant' | 'byStreet' | 'sidebar' | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSelectionToolVisible, setIsSelectionToolVisible] = useState(false); // Control the visibility of SelectionTool
  const [highlightUnconform, setHighlightUnconform] = useState(false);
  const [excludeHighway, setExcludeHighway] = useState(false);
  const [originalFeatures, setOriginalFeatures] = useState<Feature<Geometry>[]>([]);
  const [layers, setLayers] = useState<VectorLayer<VectorSource<Feature<Geometry>>>[]>([]);
  const [map, setMap] = useState<OlMap | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isToolboxFullscreen, setIsToolboxFullscreen] = useState(false);
  const [isLayerControlVisible, setIsLayerControlVisible] = useState(true);
  const [stats, setStats] = useState({
    count: 0,
    avgLength: 0,
    conformSpacings: 0,
    pctConformity: 0,
    max: 0,
  });

  const handleSetActiveTool = (tool: 'rectangle' | 'polygon' | 'byHydrant' | 'byStreet' | 'sidebar' | null) => {
    setActiveTool(tool);
    // setIsStatisticsToolActive(tool !== null && tool !== 'sidebar');
    if (tool === 'sidebar') {
      setIsStatisticsToolActive(false);
    } else {
      setIsStatisticsToolActive(true);
    }
  };
  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  const handleLayerVisibilityChange = (layerName: LayerName) => {
    setLayerVisibility((prevVisibility) => ({
      ...prevVisibility,
      [layerName]: !prevVisibility[layerName],
    }));
  };

  const filterFeaturesByLength = () => {
    const segmentLayer = layers[0];
    const source = segmentLayer?.getSource();
    if (source) {
      setPopupVisibility(false);
      console.log("seting pop up visibility to false")
      const allFeatures = source.getFeatures() as Feature<Geometry>[];
      allFeatures.forEach((feature) => feature.setStyle(defaultStyle));

      const matchedFeatures = allFeatures.filter((feature) => {
        const length = parseFloat(feature.get('length'));
        return length > parseFloat(userInput);
      });

      matchedFeatures.forEach((feature) => feature.setStyle(selectedStyle));
      setExceedingFeatures(matchedFeatures);

      if (matchedFeatures.length > 0) {
        setIsSidebarOpen(true);
      }
    }
  };

  const clearSelection = () => {
    setUserInput('');
    setExceedingFeatures([]);
    setSelectedFeature(null);
    setHighlightUnconform(false)
    setStats({ count: 0, avgLength: 0, conformSpacings: 0, pctConformity: 0, max: 0 });
    if (layers[0]) {
      const source = layers[0].getSource();
      if (source) {
        const allFeatures = source.getFeatures();
        allFeatures.forEach((feature) => feature.setStyle(defaultStyle));
      }
    }
  };

  // New effect to filter unconform features
  useEffect(() => {
    const segmentLayer = layers[0];
    const source = segmentLayer?.getSource();
    if (source && highlightUnconform) {
      // setPopupVisibility(false);
      // console.log("seting pop up visibility t fff")
      const allFeatures = source.getFeatures() as Feature<Geometry>[];
      allFeatures.forEach((feature) => feature.setStyle(defaultStyle));

      const unconformFeatures = allFeatures.filter((feature) => feature.get('non_compli') === 1);

      unconformFeatures.forEach((feature) => feature.setStyle(selectedStyle));
      setExceedingFeatures(unconformFeatures);

      if (unconformFeatures.length > 0) {
        setIsSidebarOpen(true);
      }
    } else {
      clearSelection(); // Clear selection if the checkbox is unchecked
    }
  }, [highlightUnconform, layers]);

//Filter out highway and freeway
  useEffect(() => {
    const segmentLayer = layers[0];
    const source = segmentLayer?.getSource();
    if (source) {
      if (excludeHighway) {
        const nonHighwayFeatures = originalFeatures.filter((feature) => feature.get('highway') === 'other');
        source.clear(); // Clear current features
        source.addFeatures(nonHighwayFeatures); // Add non-highway features only
      } else {
        source.clear(); // Clear current features
        source.addFeatures(originalFeatures); // Add all features back
      }
    }
  }, [excludeHighway, layers]);

  const toggleInteractiveMode = () => {
    if (interactiveMode) {
      setActiveTool(lastActiveTool);
    } else {
      setLastActiveTool(activeTool);
      setActiveTool(null);
    }
    setInteractiveMode(!interactiveMode);
  };

  const toggleLayerControl = () => {
    console.log('Toggling Layer Control:', isLayerControlVisible); // Check current state
    setIsLayerControlVisible((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await fetch('/edge_approach.geojson');
        if (!response1.ok) {
          throw new Error('Failed to fetch edge_approach.geojson');
        }
        const data1 = await response1.json();

        const vectorSource = new VectorSource<Feature<Geometry>>({
          features: new GeoJSON().readFeatures(data1),
        });
        setOriginalFeatures(vectorSource.getFeatures());
        const newVectorLayer = new VectorLayer({
          source: vectorSource,
          style: defaultStyle,
          visible: true,
        });

        const response2 = await fetch('/hydrants_reprojected.geojson');
        if (!response2.ok) {
          throw new Error('Failed to fetch hydrants_reprojected.geojson');
        }
        const data2 = await response2.json();

        const pointsSource = new VectorSource<Feature<Geometry>>({
          features: new GeoJSON().readFeatures(data2),
        });
        const newPointsLayer = new VectorLayer({
          source: pointsSource,
          style: new Style({
            image: new CircleStyle({
              radius: 3,
              fill: new Fill({
                color: '#3399ff',
              }),
            }),
          }),
          visible: true,
        });

        setLayers([newVectorLayer, newPointsLayer]);

        const streetData = await fetchStreetsInSantaBarbara();
        setStreetData(streetData); // Store street data
      } catch (error) {
        console.error('Error fetching or processing data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (layers.length > 0) {
      layers[0].setVisible(layerVisibility.mainLayer);
      layers[1].setVisible(layerVisibility.pointsLayer);
    }
  }, [layerVisibility, layers]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        console.log("it is a mobile device");
        setIsMobile(true); // This will be true in the next render
        setIsLayerControlVisible(false);
      } else {
        setIsMobile(false); // Reset state for larger screens
        setIsLayerControlVisible(true);
      }
    };
  
// Call the handler once to check initial size
handleResize();

// Add event listener for window resize
window.addEventListener('resize', handleResize);

// Cleanup listener on component unmount
return () => window.removeEventListener('resize', handleResize);
}, []);
  


  return (
    <div className="WebMercatorMap relative">
      {/* Selection Tool Container */}
      <div
        className={`selection-tool-container ${
          isMobile
            ? `fixed inset-0 z-50 bg-white overflow-y-scroll transition-transform duration-300 ${
                isSelectionToolVisible ? 'translate-y-0' : 'translate-y-full'
              }`
            : 'relative'
        }`}
      >
        {/* Minimize/Expand Button */}
        {isMobile && (
          <button
            onClick={() => setIsSelectionToolVisible(!isSelectionToolVisible)}
            className="absolute top-0 right-4 bg-white p-2 rounded-full z-50"
          >
            {isSelectionToolVisible ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        )}
  
        {/* Render SelectionTool here */}
        {isSelectionToolVisible && (
          <SelectionTool
            isMobile={isMobile} 
            selectByArea={(type) => {
              setInteractiveMode(false);
              handleSetActiveTool(type);
            }}
            selectByStreet={(streetName) => {
              setStreetName(streetName);
              setInteractiveMode(false);
              handleSetActiveTool('byStreet');
              return selectByStreet(layers, streetName, streetData!, map!, excludeHighway)
                .then((stats) => {
                  setStats(stats || { count: 0, avgLength: 0, conformSpacings: 0, pctConformity: 0, max: 0 });
                  return stats;
                });
            }}
            selectByHydrant={() => {
              setInteractiveMode(false);
              handleSetActiveTool('byHydrant');
            }}
            selectBySidebar={() => {
              setInteractiveMode(false);
              handleSetActiveTool('sidebar');
            }}
            clearSelection={clearSelection}
            streetData={streetData}
            userInput={userInput}
            onInputChange={handleInputChange}
            onSubmit={filterFeaturesByLength}
            features={exceedingFeatures}
            selectedFeature={selectedFeature}
            onSelectFeature={setSelectedFeature}
            hidePopup={() => setPopupVisibility(false)}
            setIsSelectionToolVisible={setIsSelectionToolVisible}
            highlightUnconform={highlightUnconform}
            setHighlightUnconform={setHighlightUnconform}
            map1={map}
            setStats={setStats}
          />
        )}
      </div>
  
      {/* Control Layer */}
      <div
        className={`layer-controls ${
          isLayerControlVisible ? 'block' : 'hidden'
        } bg-white bg-opacity-70 p-4 rounded-lg shadow-lg absolute top-4 right-4 !z-10`}
      >
        <label className="block mb-2">
          <input
            type="checkbox"
            checked={layerVisibility.mainLayer}
            onChange={() => handleLayerVisibilityChange('mainLayer')}
            className="mr-2"
          />
          Spacings
        </label>
        <label className="block mb-2">
          <input
            type="checkbox"
            checked={layerVisibility.pointsLayer}
            onChange={() => handleLayerVisibilityChange('pointsLayer')}
            className="mr-2"
          />
          Hydrants
        </label>
        <label className="block mb-2">
          <input
            type="checkbox"
            checked={excludeHighway}
            onChange={() => setExcludeHighway((prev) => !prev)}
            className="mr-2"
          />
          Exclude Highway
        </label>
        <button
          onClick={toggleInteractiveMode}
          className={`mr-2 text-white rounded shadow ${
            interactiveMode ? 'bg-yellow' : 'bg-blue'
          } hover:bg-blue`}
        >
          {interactiveMode ? 'Disable Interactive Mode' : 'Enable Interactive Mode'}
        </button>
        <button
          onClick={() => setIsSelectionToolVisible((prev) => !prev)}
          className="flex items-center space-x-2 bg-white text-black rounded shadow hover:bg-gray-300 p-2"
        >
          <FaTools />
          <span>Selection Toolbox</span>
        </button>
      </div>
  
      {/* Mobile Layer Control Toggle */}
      {isMobile && (
        <>
          {console.log('Rendering mobile layer control toggle, isLayerControlVisible:', isLayerControlVisible)}
          <button
            onClick={toggleLayerControl}
            className="relative top-16 right-4 bg-white rounded-full p-2 z-40"
            style={{ position: 'fixed' }} // Ensure fixed position
          >
            {isLayerControlVisible ? <FaChevronUp /> : <FaChevronDown />}
          </button>
        </>
      )}
  
      {/* Interactive Map */}
      <InteractiveMap
        layers={layers}
        layerVisibility={layerVisibility}
        currentlySelectedFeature={selectedFeature}
        changeSelectedFeature={setSelectedFeature}
        isPopupVisible={isPopupVisible}
        setPopupVisible={setPopupVisibility}
        selectByArea={(geometry) => selectByArea(layers, geometry, excludeHighway)}
        selectByHydrant={(hydrantId) => selectByHydrant(layers, hydrantId, excludeHighway)}
        selectByStreet={() =>
          selectByStreet(layers, streetName, streetData!, map!, excludeHighway)
        }
        activeTool={activeTool}
        setMapRef={setMap}
        stats={stats}
        setStats={setStats}
        showStatistics={isStatisticsToolActive}
        interactiveMode={interactiveMode}
        streetName={streetName}
      />
    </div>
  );
  
  // Export the WebMercatorMap component
} 

export default WebMercatorMap;

  
