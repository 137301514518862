import React, { useState, useMemo } from 'react';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { selectedStyle, topStyle } from '../WebMercatorMap/utilities ';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import { Extent } from 'ol/extent';
import { Map as OlMap } from 'ol'

interface FeatureListProps {
  features: Feature<Geometry>[];
  currentlySelectedFeature: Feature<Geometry> | null;
  changeSelectedFeature: React.Dispatch<React.SetStateAction<Feature<Geometry> | null>>;
  hidePopup: () => void;
  map: OlMap;
}

const FeatureList: React.FC<FeatureListProps> = ({
  features,
  currentlySelectedFeature,
  changeSelectedFeature,
  hidePopup,
  map,
}) => {
  const [selectedFeatureId, setSelectedFeatureId] = useState<number | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const sortedFeatures = useMemo(() => {
    const sorted = [...features].sort((a, b) => {
      const lengthA = parseFloat(a.get('length'));
      const lengthB = parseFloat(b.get('length'));
      return sortOrder === 'asc' ? lengthA - lengthB : lengthB - lengthA;
    });
    return sorted;
  }, [features, sortOrder]);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const onFeatureClick = (feature: Feature<Geometry>) => {
    if (currentlySelectedFeature) {
      currentlySelectedFeature.setStyle(selectedStyle);
      console.log('it is gettign here')
    }
    
    feature.setStyle(topStyle);
    changeSelectedFeature(feature);


    if (map){
      const geom = feature.getGeometry();
      if (geom){
        const extent = geom.getExtent();
        map.getView().fit(extent,{duration:1000, maxZoom: 18})
      }
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const featuresPerPage = 5; // e.g., display 5 features per page

  const totalPages = Math.ceil(sortedFeatures.length / featuresPerPage);

  const indexOfLastFeature = currentPage * featuresPerPage;
  const indexOfFirstFeature = indexOfLastFeature - featuresPerPage;
  const currentFeatures = sortedFeatures.slice(indexOfFirstFeature, indexOfLastFeature);

  const navigatePage = (direction: number) => {
    setCurrentPage((prev) => Math.min(Math.max(1, prev + direction), totalPages));
  };

  return (
    <div className="p-1 border rounded-md">
      <div className="flex justify-between items-center mb-2">
        <div className="text-lg font-bold">Spacings</div>
        <div
          className="relative flex items-center cursor-pointer"
          onClick={toggleSortOrder}
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
        >
          {sortOrder === 'asc' ? <FaSortAlphaDown /> : <FaSortAlphaUp />}
          {tooltipVisible && (
            <div className="absolute top-full mt-1 px-2 py-1 text-xs text-white bg-gray-800 rounded">
              {sortOrder === 'asc' ? 'Sort Descending' : 'Sort Ascending'}
            </div>
          )}
        </div>
      </div>

      {currentFeatures.map((feature, idx) => {
        const featureId = feature.getId();
        const isHighlighted = selectedFeatureId === featureId;
        const length = parseFloat(feature.get('length')).toFixed(2);
        return (
          <div
            key={idx}
            onClick={() => {
              // hidePopup();
              if (feature === currentlySelectedFeature) {
                feature.setStyle(selectedStyle);
                changeSelectedFeature(null); // deselect the feature
              } else {
                onFeatureClick(feature);
                const featureId = feature.getId();
                if (typeof featureId === 'number') {
                  setSelectedFeatureId(featureId);
                }
              }
            }}
            className={`cursor-pointer p-2 border mb-2 rounded-md transition-colors ${
              isHighlighted ? 'bg-gray-900' : 'bg-transparent'
            }`}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#eaeaea')}
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = isHighlighted ? '#34ebcf' : 'transparent')
            }
          >
            <span>Length: {length} feet </span>
          </div>
        );
      })}

      <div className="flex justify-center mt-4">
        <button
          onClick={() => navigatePage(-1)}
          disabled={currentPage === 1}
          className="py-1 px-3 bg-blue-500 text-yellow rounded-md disabled:bg-gray-300 mr-2"
        >
          Previous
        </button>
        <span className="px-2">Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => navigatePage(1)}
          disabled={currentPage === totalPages}
          className="py-1 px-3 bg-blue-500 text-yellow rounded-md disabled:bg-gray-300 ml-2"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default FeatureList;
