import { Stroke, Style, Fill } from 'ol/style';
import CircleStyle from 'ol/style/Circle';


export const defaultStyle = new Style({
    stroke: new Stroke({
        color: '#aebfbf',
        width: 5
    })
});

export const selectedStyle = new Style({
    stroke: new Stroke({
        color: '#f285a0',
        width: 7
    }),
    zIndex: 10
});

export const topStyle = new Style({
    stroke: new Stroke({
        color: '#4cd4c6',
        width: 12
    }),
    zIndex: 20 // Setting a zIndex to ensure this feature is drawn on top of others
})

export const InteractiveStyle = new Style({
    stroke: new Stroke({
        // color: '#4293f5',
        color: '#da1afb',
        width: 10
    }),
    zIndex: 30 // Setting a zIndex to ensure this feature is drawn on top of others
})

export const SelectedPoint = new Style({
    image: new CircleStyle({
      radius: 10, // Increase the size to make it larger
      fill: new Fill({ color: '#da1afb' }), // Customize color
    }),
  })

declare global {
    interface Window {
        myMap: any;
    }
}