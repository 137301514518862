import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import { defaultStyle, selectedStyle, topStyle, InteractiveStyle, SelectedPoint } from './utilities ';

interface HandleFeatureSelectParams {
  feature: Feature<Geometry> | null;
  currentlySelectedFeature: Feature<Geometry> | null;
  popupRef: any;
  setPopupContent: React.Dispatch<React.SetStateAction<string | null>>;
  setPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isPopupVisible: boolean;
  e: any;
}

export const handleFeatureSelect = ({
  feature,
  currentlySelectedFeature,
  popupRef,
  setPopupContent,
  setPopupVisible,
  isPopupVisible,
  e,
}: HandleFeatureSelectParams) => {
  // If the new feature is the same as the currently selected feature, do nothing
  // if (feature === currentlySelectedFeature) {
  //   console.log("It is getting into the first stuuf")
  //   return currentlySelectedFeature;
  // }

  // Reset the currently selected feature's style, but do not hide popup
  if (currentlySelectedFeature) {
    
    currentlySelectedFeature.setStyle(currentlySelectedFeature.getStyle());
    // setPopupVisible(true)

  }

  // Handle new feature selection
  if (feature) {
    const resolution = e.mapBrowserEvent.map.getView().getResolution();
    const coordinate = e.mapBrowserEvent.coordinate;
    const GeomFeature = feature.getGeometry();
    
    if (GeomFeature) {
      const isPointLayer = GeomFeature.getType() === 'Point';
      if (isPointLayer) {
        const hydid = feature.get('id');
        const hydglobalid = feature.get('GlobalID');
        const landUse = feature.get('LandUse');

        if (isPopupVisible) {
          const popupLines = [
            `Hydrant ID: ${hydid}`,
            `Global ID: ${hydglobalid}`,
            `Land Use: ${landUse}`,
          ];
          setPopupContent(popupLines.join('\n'));
          popupRef.current.setPosition([coordinate[0], coordinate[1] + 8 * resolution]);
          setPopupVisible(true);
          console.log('making sure we diplay things poppu==true')
        }
      } else {
        const featureLength = parseFloat(feature.get('length'));
        const source = feature.get('source');
        const target = feature.get('target');
        const standard = feature.get('joint_stan');

        
          const popupLines = [
            `Length: ${featureLength.toFixed(2)} feet`,
            `Source: ${source}`,
            `Target: ${target}`,
            `Standard: ${standard}`,
          ];
          if (isPopupVisible ) {
          setPopupContent(popupLines.join('\n'));
          popupRef.current.setPosition([coordinate[0], coordinate[1] + 8 * resolution]);
          // setPopupVisible(true);
          setPopupVisible(true);
          console.log('making sure we diplay things poppu==true')
        }
      }
    }
    return feature; // Return the newly selected feature
  }
  // setPopupVisible(false)  
  popupRef.current?.setPosition(undefined); // Reset the popup position
  return null; // Return null if no feature is selected

};


// Handle feature click logic
export const handleFeatureClick = (
  feature: Feature<Geometry>,
  currentlySelectedFeature: Feature<Geometry> | null,
) => {
  if (currentlySelectedFeature) {
    currentlySelectedFeature.setStyle(selectedStyle);
  }

  if (feature) {
    const GeomFeature = feature.getGeometry();
    if (GeomFeature){
      const isPointLayer = GeomFeature.getType() === 'Point';
      if (isPointLayer) {
        feature.setStyle(SelectedPoint);
      } else {  
        feature.setStyle(InteractiveStyle);
      }
    }
  }

  return feature; // Returning the newly selected feature
};


// Handle popup logic (You can extend this based on your requirements)
export const handlePopup = (
  isPopupVisible: boolean,
  setPopupVisible: React.Dispatch<React.SetStateAction<boolean>>,
  popupRef: any,
) => {
  if (popupRef && !isPopupVisible) {
    popupRef.current?.setPosition(undefined);
  }

  setPopupVisible(!isPopupVisible); // Toggling popup visibility
  console.log(setPopupVisible)
};
