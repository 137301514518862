import React, { useEffect, useRef, useState, Suspense, useCallback } from 'react';
import { Feature, Map, View } from 'ol';
import Geometry from 'ol/geom/Geometry';
import TileLayer from 'ol/layer/Tile';
import { OSM } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import { DragBox, Select, Draw } from 'ol/interaction';
import { click, platformModifierKeyOnly } from 'ol/events/condition';
import VectorSource from 'ol/source/Vector';
import Overlay from 'ol/Overlay';
import { defaultStyle, selectedStyle,topStyle} from './utilities ';
import { SelectEvent } from 'ol/interaction/Select';
import { handleFeatureSelect, handleFeatureClick } from './maputils';
import Polygon from 'ol/geom/Polygon';
import { Extent } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';
import { Map as OlMap } from 'ol';
import { FeatureCollection } from 'geojson';
// import StatisticsDisplay from './Statistics' 
import Draggable from 'react-draggable';

interface InteractiveMapProps {
    layers: VectorLayer<VectorSource<Feature<Geometry>>>[];
    layerVisibility: {
      mainLayer: boolean;
      pointsLayer: boolean;
    };
    currentlySelectedFeature: Feature<Geometry> | null;
    changeSelectedFeature: React.Dispatch<React.SetStateAction<Feature<Geometry> | null>>;
    isPopupVisible: boolean;
    setPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
    selectByArea: (geometry: Polygon) => void;
    selectByHydrant: (hydrantId: number) => void;
    selectByStreet: (streetName: string) => Promise<{ count: number; avgLength: number; conformSpacings: number; pctConformity: number; max: number } | null>;
    activeTool: 'rectangle' | 'polygon' | 'byHydrant' | 'byStreet' | 'sidebar'| null;
    setMapRef: (map: OlMap) => void;
    stats: {
        count: number;
        avgLength: number;
        conformSpacings: number;
        pctConformity: number;
        max: number;
      };
      setStats: React.Dispatch<React.SetStateAction<{
        count: number;
        avgLength: number;
        conformSpacings: number;
        pctConformity: number;
        max: number;
      }>>; // Add setStats prop
      showStatistics: boolean; 
      interactiveMode: boolean;
      streetName: string;
  }

  const InteractiveMap: React.FC<InteractiveMapProps> = ({
    layers,
    layerVisibility,
    currentlySelectedFeature,
    changeSelectedFeature,
    isPopupVisible,
    setPopupVisible,
    selectByArea,
    selectByHydrant,
    selectByStreet,
    activeTool,
    setMapRef,
    stats,
    setStats,
    showStatistics,
    interactiveMode,
    streetName,
  }) => {
    const mapRef = useRef<HTMLDivElement | null>(null);
    const olMapRef = useRef<Map | null>(null);
    const [popupContent, setPopupContent] = useState<string | null>(null);
    const popupRef = useRef<Overlay | null>(null);
    const popupDivRef = useRef<HTMLDivElement | null>(null);
    const [drawLayer, setDrawLayer] = useState<VectorLayer<VectorSource<Feature<Geometry>>> | null>(null);
    const [interactions, setInteractions] = useState<any[]>([]);

  useEffect(() => {
    if (!mapRef.current || olMapRef.current) {
        return;
      }
      

    olMapRef.current = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        ...layers,
      ],
      view: new View({
        projection: 'EPSG:3857',
        center: fromLonLat([-119.6982, 34.4208]),
        zoom: 14,
      }),
    });
    setMapRef(olMapRef.current);

    const newDrawLayer = new VectorLayer<VectorSource<Feature<Geometry>>>({
      source: new VectorSource<Feature<Geometry>>(),
      style: topStyle,
    });

    olMapRef.current.addLayer(newDrawLayer);
    setDrawLayer(newDrawLayer);

    olMapRef.current.updateSize();

    console.log("Map's layers after initialization:", olMapRef.current.getLayers().getArray());

    window.myMap = olMapRef.current;
  }, [layers, setMapRef]);

  const handleStreetSelection = useCallback(async () => {
    console.log('handleStreetSelection called');
      const stats = await selectByStreet(streetName);
      console.log('Street selection stats:', stats);
      setStats(stats || { count: 0, avgLength: 0, conformSpacings: 0, pctConformity: 0, max: 0 });
  }, [selectByStreet,stats]);

   // UseEffect to trigger street selection
   useEffect(() => {
    console.log('Active tool changed:', activeTool);
    if (activeTool === 'byStreet') {
      console.log('Triggering handleStreetSelection');
        handleStreetSelection();
    }
}, [activeTool]);

  useEffect(() => {
    if (!olMapRef.current) {
      return;
    }

    // Clear previous interactions
    interactions.forEach((interaction) => {
      olMapRef.current!.removeInteraction(interaction);
    });

    const newInteractions: any[] = [];

    const selectHydrant = new Select({
        layers: [layers[1]], // Assuming you have a separate hydrantLayer
        condition: click,
      });

    if (activeTool === 'rectangle') {
      const dragBox = new DragBox({
        condition: platformModifierKeyOnly,
      });

      olMapRef.current.addInteraction(dragBox);
      newInteractions.push(dragBox);

      dragBox.on('boxend', () => {
        const extent = dragBox.getGeometry().getExtent();
        const polygon = fromExtent(extent);
        const stats = selectByArea(polygon);
        if (stats!){
            setStats(stats)
          } else {
            setStats({ count: 0, avgLength: 0, conformSpacings: 0, pctConformity: 0, max: 0 });
          }
      });
    } else if (activeTool === 'polygon') {
      const drawSource = drawLayer?.getSource() as VectorSource<Feature<Geometry>>;
      if (drawSource) {
        drawSource.clear(); // Clear previous drawings

        const draw = new Draw({
          source: drawSource,
          type: 'Polygon',
        });

        olMapRef.current.addInteraction(draw);
        newInteractions.push(draw);

        draw.on('drawend', (event) => {
          const polygon = event.feature.getGeometry() as Polygon;
          const stats = selectByArea(polygon);
          if (stats!){
            setStats(stats)
          } else {
            setStats({ count: 0, avgLength: 0, conformSpacings: 0, pctConformity: 0, max: 0 });
          }
          drawSource.clear(); // Clear the drawn polygon after selection
        });
      }
    } else if (activeTool=== 'byHydrant'){
        selectHydrant.on('select', (e: SelectEvent) => {
            if (e.selected.length > 0) {
              const hydrant = e.selected[0];
              const hydrantId = hydrant.get('id'); // Replace 'id' with the actual property name
              if (hydrantId) {
                const stats = selectByHydrant(hydrantId) ;
                if (stats!){
                    setStats(stats)
                } else {
              setStats({ count: 0, avgLength: 0, conformSpacings: 0, pctConformity: 0, max: 0 });
            }
            }
        }
          });
        olMapRef.current.addInteraction(selectHydrant);
        newInteractions.push(selectHydrant);
    } 
  

      if (interactiveMode) {
        
    const popupElement = popupDivRef.current;
    if (popupElement) {
      popupRef.current = new Overlay({
        element: popupElement,
        positioning: 'bottom-center',
        stopEvent: false,
        offset: [0, -10],
      });
      olMapRef.current.addOverlay(popupRef.current);
    }

    const select = new Select();
    olMapRef.current.addInteraction(select);
    newInteractions.push(select);

    const handleSelectEvent = (e: SelectEvent) => {
      const feature = e.selected[0];
      console.log("it is selecting inside interactve mode")
      
      setPopupVisible(true);
      // console.log("setting the pupup visibility to true", isPopupVisible)
      // console.log('changed popup == true')
      const newSelectedFeature = handleFeatureSelect({
        feature,
        currentlySelectedFeature,
        popupRef,
        setPopupContent,
        setPopupVisible,
        isPopupVisible,
        e,
      });
      handleFeatureClick(feature,newSelectedFeature);
      // setPopupVisible(false);

    };

    select.on('select', handleSelectEvent);
} else {
  // setPopupVisible(false); 
  popupRef.current?.setPosition(undefined); //
}

    setInteractions(newInteractions);

    return () => {
      newInteractions.forEach((interaction) => {
        olMapRef.current!.removeInteraction(interaction);
      });
    };
  }, [ activeTool, layers, selectByArea, drawLayer]);

  useEffect(() => {
    if (!olMapRef.current) {
      return;
    }

    const currentLayers = olMapRef.current.getLayers().getArray();
    const layersToRemove = currentLayers.filter((layer) => !(layer instanceof TileLayer) && layer !== drawLayer);
    layersToRemove.forEach((layer) => {
      if (olMapRef.current) {
        olMapRef.current.removeLayer(layer);
      }
    });

    layers.forEach((layer, index) => {
      if (olMapRef.current) {
        olMapRef.current.addLayer(layer);
        layer.setVisible(index === 0 ? layerVisibility.mainLayer : layerVisibility.pointsLayer);
      }
    });
  }, [layers, layerVisibility, drawLayer]);
  
  useEffect(() => {
    console.log("Popup visibility updated:", isPopupVisible);
  }, [isPopupVisible]);

  useEffect(() => {
    console.log("showStatistics has changed:", showStatistics);
  }, [showStatistics]);

  useEffect(() => {
    if (!interactiveMode) {
      // setPopupVisible(false); // Hide the popup when interactive mode is off
      popupRef.current?.setPosition(undefined); // Reset the popup position
    }
  }, [interactiveMode]); // Runs this effect when interactiveMode changes

  const StatisticsDisplay = React.lazy(() => import('./Statistics'));
  

  return (
    <div className="InteractiveMap flex flex-col h-full">
    <div ref={mapRef} className="flex-grow"  style={{ width: '100%', height: '80%' }} />
    <div>
    <div className="flex-shrink-0 py-2" style={{ maxHeight: 'calc(20vh)' }}>
      <Suspense fallback={<div>Loading statistics...</div>}>
        {showStatistics && <StatisticsDisplay stats={stats} />}
      </Suspense>
    </div>
    </div>

    
    <div>
      {isPopupVisible && (
      <div ref={popupDivRef} className="ol-popup relative z-50 py-10 bg-white rounded-lg shadow-md cursor-move">
        <button
          onClick={() => {
            // setPopupVisible(false); 
            popupRef.current?.setPosition(undefined); // Reset the popup position
          }}
          className="absolute top-0 right-2 text-gray-400 hover:text-gray-800"
        >
          ✕
        </button>
        {(popupContent || '').split('\n').map((line, index) => (
          <div key={index} className="py-1 mt-2 mb-1">
            {line}
          </div>
        ))}
      </div>)}
  </div>

  </div>
  );
};

export default InteractiveMap;